<!--
 * @Descripttion: 我的收藏页面组件
 * @Author: Banbri
 * @Date: 2021-05-03 22:55:29
 * @LastEditors: Banbri
 * @LastEditTime: 2021-06-08 15:59:16
--> 

<template>
  <div class="favorite" id="favorite" name="favorite">
    <div class="favorite-layout">
      <el-row :gutter="10">
        <div>
          <CenterMenu></CenterMenu>
        </div>
        <el-col :span="20">
          <div class="favorite-content">
            <div class="extra"></div>
            <div class="favorite-title">
              <p>我的收藏</p>
            </div>
            <div v-if="collectList.length>0">
              <div class="goods-list">
                <MyList :list="collectList" :isDelete="true"></MyList>
              </div>
            </div>
            <!-- 订单为空的时候显示的内容 -->
            <div v-else class="empty">
              <p>你暂未收藏任何商品</p>
            </div>
            <!-- 订单为空的时候显示的内容END -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import CenterMenu from '../components/CenterMenu'
export default {
  name: 'Favorite',
  data() {
    return {
      collectList: []
    }
  },
  methods: {
    getFavorites() {
      // 获取收藏数据
    this.$axios
      .post("/api/user/collect/getCollect", {
        user_id: this.$store.getters.getUser.user_id
      })
      .then(res => {
        if (res.data.code === "001") {
          this.collectList = res.data.collectList;
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
    }
  },
  activated() {
    this.getFavorites()
  },
  components: {
    CenterMenu
  }
}
</script>
<style scoped>
.favorite-layout {
  max-width: 1225px;
  margin: 0 auto;
}
.favorite-content {
  background-color: #ffffff;
  margin-bottom: 30px;
}
.favorite-title {
  height: 100px;
  display: flex;
  align-items: center;
}
.favorite-title p {
  font-size: 30px;
  color: #757575;
  margin-left: 50px;
}
.extra {
  height: 10px;
}
.goods-list {
  overflow: hidden;
}
.favorite-content .pagination {
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
}
.empty {
  width: 200px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 70px;
  color: #bdbaba;
  font-size: 18px;
}
</style>